import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export function useContactTypes() {
    const store = useStore()
    const contactTypes = computed(() => store.getters.contactTypes)

    const getContactTypes = () => {
        store.dispatch('fetchContactTypes')
    }

    onMounted(() => {
        if (contactTypes.value && contactTypes.value.length) {
            return
        }

        getContactTypes()
    })

    return { contactTypes, getContactTypes }
}
